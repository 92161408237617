import './src/loader-autrice.css';
import './src/styles.css';

export const onInitialClientRender = () => {
  document.body.classList.add('preloader_ready');

  setTimeout(function () {
    document.body.classList.remove('preloader_active');
    document.body.classList.remove('preloader_ready');
  }, 600);
};
